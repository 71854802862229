import { lazy, Suspense } from 'react';
import { Spinner, ChakraProvider } from '@chakra-ui/react'

import { LocaleProvider, useLocale } from '../components/LocaleProvider'
import getTheme from '../config/theme'

const ThemeWrapper = ({children}) => {
  const { locale } = useLocale();
  return (
    <ChakraProvider theme={getTheme(locale)}>
      {children}
    </ChakraProvider>
  )
};

const LazyContents = lazy(() => import('../components/Contents'));

const Loader = () => {
  return (
    <div
      style={{
        backgroundColor: '#bee3f8',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spinner
        thickness='8px'
        speed='0.65s'
        emptyColor='red.100'
        color='red.300'
        size='xl'
      />
    </div>
  )
}

function App() {
    return (
      <LocaleProvider>
        <ThemeWrapper>
        <Suspense fallback={<Loader/>}>
          <LazyContents/>
        </Suspense>
        </ThemeWrapper>
      </LocaleProvider>
    );
}

export default App;
