import React, { createContext, useContext, useState } from 'react';
import { texts as config } from './messages';

export const LocaleContext = createContext();

const LS_KEY = 'albertAndLizaWeddingLocale'

export function LocaleProvider({ children }) {
    const [locale, setLocale] = useState(
        localStorage.getItem(LS_KEY) === null
        ? 'en'
        : localStorage.getItem(LS_KEY)
    );
    const [messages] = useState(config);

    const switchLocale = (newLocale) => {
        setLocale(newLocale);
        localStorage.setItem(LS_KEY, newLocale)
    };

    return (
        <LocaleContext.Provider value={{ locale, messages: messages[locale], switchLocale }}>
            {children}
        </LocaleContext.Provider>
    );
}

export function useLocale() {
    const context = useContext(LocaleContext);
    if (context === undefined) {
        throw new Error('useLocale must be used within a LocaleProvider');
    }
    return context;
}
  