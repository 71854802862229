import { extendTheme, theme as baseTheme } from "@chakra-ui/react"

const fontByLocale = {
    ru: '"Jost", sans-serif',
    en: '"Bebas Neue", sans-serif'
}

export const getTheme = (locale) => {
    const fontFamily = fontByLocale[locale];

    const sizes = {
        sizes: {
            ...baseTheme.space,
            // max: 'max-content',
            // min: 'min-content',
            // full: '100%',
            // '3xs': '14rem',
            // '2xs': '16rem',
            // xs: '20rem',
            // sm: '24rem',
            // md: '28rem',
            // lg: '32rem',
            // xl: '36rem',
            // '2xl': '42rem',
            // '3xl': '48rem',
            // '4xl': '56rem',
            // '5xl': '64rem',
            // '6xl': '72rem',
            // '7xl': '80rem',
            // '8xl': '90rem',
            container: {
                sm: '640px',
                md: '768px',
                lg: '1024px',
                xl: '1280px',
            },
        },
    }

    const components = {
        components: {
            Button: {
                baseStyle: {
                    borderRadius: '2px',
                    variantColor: 'red',
                    fontFamily: '"Raleway", sans-serif',
                },
                variants: {
                    modal: {
                        bg: 'red.300',
                        color: 'white',
                        fontSize:{base: '18px', md: '24px'}
                    }
                }
            },
            Text: {
                baseStyle: {
                    color: 'gray.700',
                    fontFamily: '"Raleway", sans-serif',
                    fontSize:{base: '18px', md: '24px'}
                },
                variants: {
                    // used as <Text variant="secondary">
                    secondary: {
                        color: 'gray.600'
                    },
                },
            },
            Heading: {
                baseStyle: {
                    color: 'gray.700',
                    fontFamily,
                    fontSize: {base: '40px', md: '60px'},
                    ...(locale === 'ru'
                    ? {
                        textTransform: 'uppercase',
                        letterSpacing: '-0.1em',
                    }
                    : {}),
                },
                variants: {
                    head: {
                        fontSize: ['40px', '60px', '60px']
                    },
                    secondary: {
                        color: 'gray.600'
                    },
                    en: {
                        fontFamily: '"Bebas Neue", sans-serif',
                        letterSpacing: 'normal',
                    }
                },
            },
            Switch: {
                variants: {
                    lang: {
                        thumb: {
                            bg: 'red.300',
                        },
                        track: {
                            bg: 'red.100',
                            _checked: {
                            bg: 'red.100',
                            },
                        },
                    }
                }
            }
        },
    }

    return extendTheme({
        ...sizes,
        ...components,
        initialColorMode: "system",
        useSystemColorMode: false,
        colors: {
            light: {},
            dark: {}
        },
        styles: {
            global: props => ({
                // Optionally set global CSS styles
                body: {
                    color: 'gray.700',
                },
            }),
        },
    })
}

export default getTheme;