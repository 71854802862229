export const texts = {
  en: {
    heading: {
      mobile: (
        <>
          Wedding of
					<br/>
					Albert
					<br/>
					& Liza
        </>
      ),
      desktop: (
        <>
          Liza &
					<br/>
					Albert
        </>
      ),
      common: 'We will be so happy to see you at our wedding!'
    },
    when: {
      title: 'When',
      save: 'Save the date:',
      agenda: 'Agenda',
      agendaTitle: "Oops, we're not ready yet",
      agendaDescr: 'Agenda will be here a bit later',
      scheduleItems: {
        meet: ['Meet at ', 'Amstelveenseweg 184', 'for guests coming by guests bus'],
        welcome: ['Welcome drinks in', 'Zoomers aan Zee'],
        ceremony: 'Ceremony',
        cake: 'Cut the cake',
        dinner: 'Dinner',
        dance: 'Dance',
        back: 'Transfer back',
      },
    },
    where: {
      title: 'Where',
      addressTitle: 'Address:',
      addressDescr: 'Zeeweg 51, 1901 NZ Castricum',
      transfer: 'We are going to order a transfer for our guests from Amsterdam',
      transferTitle: 'For guests coming from Amsterdam:',
      transferDescr: [
        'The bus will start from',
        'Amstelveenseweg 184',
        'at 14:00.',
        'Please don\'t be late!',
      ]
    },
    rsvp: {
      title: 'Are you coming?',
      name: 'Name',
      email: 'Email',
      emailDesc: "So we can let you know if something changes",
      guests: 'Who is coming with you?',
      note: 'Please let us know if you are coming before 19.06.2024',
      toast: {
          successTitle: 'Success!',
          successDescr: 'See you soon!',
          errorTitle: 'Something went wrong :(',
          errorDescr: 'Please try again',
          loadingTitle: 'Sending your answer',
          loadingDescr: 'Please wait',
      },
      gdpr: {
        link: ['I agree to the processing of my personal data as described in ', 'the policy', '.'],
        title: 'Privacy Notice and Consent',
        texts: [
          "1. Data Collection and Use: We collect your personal data, including name, address, and email address, solely to manage invitations and responses for the wedding event. This data helps us organize the event effectively and communicate important information to you.",
          "2. Consent for Data Processing: By ticking this box, you provide your explicit consent for us to process and store your personal data for the purposes outlined above. Your data will not be used for any other purposes without your additional explicit consent.",
          "3. Data Retention: Your personal data will be retained only as long as necessary for the completion of the wedding event, after which it will be securely deleted.",
          "4. Your Data Rights: You have the right to access, correct, delete, or transfer your personal data at any time. You also have the right to withdraw your consent and object to our processing of your data. To exercise these rights, please contact us at making.liza@sverdlov.me .",
          "5. Data Security: We are committed to ensuring the security of your personal data. We take appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of your data.",
          "6. Contact Information: For any questions or concerns regarding your personal data, please contact us at making.liza@sverdlov.me ."
        ],
      }
    },
    clothes: {
      title: 'What to wear',
      weather: 'Weather',
      colors: 'Colors',
      inspo: 'Inspo',
      clrs: {
        title: 'Colors',
        do: 'Please do:',
        dont: "Please don't:",
        comment: "Please treat this as a recommendation and feel free to check with us if you're not sure😉",
      },
      wthr: {
        title: 'Weather',
        descr1: 'Check out the weather forecast',
        descr2: 'here',
        descr3: "! Just a heads-up, we're in the Netherlands, so expect a little bit of everything—sunshine, rain, and maybe a surprise or two. That's Dutch weather for you! 😊"
      },
      insp: {
        title: 'Inspiration'
      }
    },
    presents: {
      title: 'Presents',
      descr1: 'Your presence at our wedding is the greatest gift of all!',
      descr2: 'However, if you wish to honor us further, we dream of adventuring through Japan. A contribution towards this journey would be deeply cherished and appreciated.',
      btn: 'Make a gift',
      modal: {
        title: 'Details',
        descr: "Please don't forget to leave your name😘",
        cardbox: 'You can use a physical card box in the restaurant',
      },
    },
    footer: "Thank you for reading! Can't wait to see you on our wedding day",
    common: {
      close: 'Close',
      submit: 'Submit',
    }
  },
  ru: {
    heading: {
      mobile: (
        <>
          Свадьба
          <br/>
          Альберта
          <br/>
          и Лизы
        </>
      ),
      desktop: (
        <>
          Лиза и
          <br/>
          Альберт
        </>
      ),
      common: "Мы будем очень рады видеть вас на нашем торжестве!"
    },
    when: {
      title: "Дата",
      save: "Запишите дату:",
      agenda: "Программа",
      agendaTitle: "Пока что все в процессе подготовки",
      agendaDescr: "Детальная программа появится позже",
      scheduleItems: {
        meet: ['Встреча у ', 'Амстелвенсевег 184', 'для гостей, прибывающих на гостевом автобусе'],
        welcome: ['Приветственные напитки в', 'Zoomers aan Zee'],
        ceremony: 'Церемония',
        cake: 'Разрезание торта',
        dinner: 'Ужин',
        dance: 'Танцы',
        back: 'Трансфер обратно',
      },
    },
    where: {
      title: "Место проведения",
      addressTitle: "Адрес:",
      addressDescr: "Zeeweg 51, 1901 NZ Castricum",
      transfer: "Мы организуем трансфер для гостей из Амстердама",
      transferTitle: 'Для гостей из Амстердама:',
      transferDescr: [
        'Автобус отъедет от',
        'Амстелвенсевег 184',
        'в 14:00.',
        'Пожалуйста, не опаздывайте!'
      ]
    },
    rsvp: {
      title: "Вы присоединитесь к нам?",
      name: "Имя",
      email: "Email",
      emailDesc: "Чтобы мы могли дать вам знать, что что-то изменилось",
      guests: "Кто будет с вами?",
      note: 'Пожалуйста, сообщите о своем присутствии до 19.06.2024',
      toast: {
        successTitle: 'Ура!',
        successDescr: 'До скорой встречи!',
        errorTitle: 'Что-то пошло не так :(',
        errorDescr: 'Попробуйте еще раз',
        loadingTitle: 'Отправляем ваш ответ',
        loadingDescr: 'Пожалуйста, подождите',
      },
      gdpr: {
        link: ['Я согласен на обработку моих личных данных в соответствии с ', 'политикой конфиденциальности', '.'],
        title: 'Уведомление о конфиденциальности и согласие на обработку данных',
        texts: [
          "1. Сбор и использование данных: Мы собираем ваши личные данные, включая имя, адрес и адрес электронной почты, исключительно для управления приглашениями и ответами на свадебное мероприятие. Эти данные помогают нам эффективно организовать мероприятие и передавать вам важную информацию.",
          "2. Согласие на обработку данных: Отметив этот флажок, вы даете свое явное согласие на обработку и хранение ваших личных данных в целях, описанных выше. Ваши данные не будут использованы в иных целях без вашего дополнительного явного согласия.",
          "3. Хранение данных: Ваши личные данные будут сохраняться только на тот период, который необходим для завершения свадебного мероприятия, после чего они будут надежно удалены.",
          "4. Ваши права на данные: Вы имеете право в любое время получить доступ к своим личным данным, исправить ошибки, удалить или перенести свои личные данные. У вас также есть право отозвать свое согласие и возражать против обработки ваших данных. Чтобы воспользоваться этими правами, пожалуйста, свяжитесь с нами по почте making.liza@sverdlov.me .",
          "5. Безопасность данных: Мы обязуемся обеспечивать безопасность ваших личных данных. Мы принимаем соответствующие меры безопасности для защиты от несанкционированного доступа, изменения, разглашения или уничтожения ваших данных.",
          "6. Контактная информация: Если у вас есть вопросы или опасения по поводу ваших личных данных, пожалуйста, свяжитесь с нами по почте making.liza@sverdlov.me ."
        ],
      }
    },
    clothes: {
      title: "Дресс-код",
      weather: "Погода",
      colors: "Цвета",
      inspo: "Вдохновение",
      clrs: {
        title: "Цветовая гамма",
        do: "Рекомендуется:",
        dont: "Не рекомендуется:",
        comment: "Это всего лишь рекомендации, поэтому при возникновении вопросов не стесняйтесь спрашивать нас😉",
      },
      wthr: {
        title: "Погодные условия",
        descr1: "Не забудьте проверить прогноз погоды",
        descr2: "здесь",
        descr3: "! Имейте в виду, что мы в Нидерландах: тут бывает все – солнце, дождь и возможны неожиданности. Добро пожаловать в голландскую погоду! 😊"
      },
      insp: {
        title: "Идеи для вдохновения"
      }
    },
    presents: {
      title: "Подарки",
      descr1: "Ваше присутствие будет для нас лучшим подарком!",
      descr2: "Если же вы хотите сделать нам особенный подарок, мы мечтаем о путешествии по Японии. Ваш вклад в эту мечту будет нам особенно дорог.",
      btn: "Подарить",
      modal: {
        title: "Подробности",
        descr: "Пожалуйста, не забудьте оставить свое имя😘",
        cardbox: 'Можно воспользоваться физической коробочкой для открыток в ресторане',
      },
    },
    footer: "Благодарим за внимание! С нетерпением ждем встречи на свадьбе",
    common: {
      close: "Закрыть",
      submit: 'Отправить',
    }
  },
}
